
login-component {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.login-container {
    border-radius: 0.5em;
    max-width: 20em;

    h1 {
        margin: 0;
        font-size: 1em;
    }

    h1,
    p {
        padding: 0 0.5em;
    }

    form {
        padding: 1em;
        text-align: center;
    }
}