home-component {
    display: flex;
    justify-content: center;
    height: 100vh;
    & button {
        width: 10em;
    }
    text-align: center;
}

.spinner-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 0.5em;
    margin-top: 0.5em;
    height: 2em;
}

.group {
    margin-bottom: 2em;
}

#volume-slider {
    width: 100%;
}